<template>
  <div>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card max-width="50vw" :elevation="hover ? 24 : 6">
          <div class="text-left itemsTop">
            <v-chip class="ma-2" :color="complete ? 'success' : 'error'" small outlined>{{complete ? 'Completado' : 'Incompleto'}}</v-chip>
          </div>
          <div class="text-center">
            <h2 class="titulo text-center" v-text="title" />
          </div>
          <div class="text-right itemsTop" v-if="loanId > 0">
            <v-tooltip top
              ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2 mt-3" size="22" color="primary" dark v-bind="attrs" v-on="on" @click="redirect(loanId, section)">mdi-file-document-edit-outline</v-icon></template
              ><span v-text="'Actualizar ' + title"
            /></v-tooltip>
          </div>
          <v-container>
            <v-row v-for="(item, i) in mainInfo" :key="i">
              <v-spacer></v-spacer>
              <v-col cols="5" class="paddingList">
                <p class="grey--text text--darken-2" v-text="keys[i]" />
              </v-col>
              <v-col cols="5" class="paddingList">
                <p class="black--text text--darken-2" v-text="item ? item : 'No se encontró información'" />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <div v-if="expand">
              <slot class="text-center" name="more-info" />
              <div v-if="detailItems.length > 0">
                <v-row v-for="(item, i) in otherInfo" :key="i">
                  <v-col cols="12" class="paddingList">
                    <span class="grey--text text--darken-2" v-text="keys[i]" />
                    <br />
                    <span class="black--text text--darken-2" v-text="item" />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
          <slot name="actions" ></slot>
          <div v-if="detailItems.length > 0 || forceExpand" class="semiCircle" @click="expand = !expand">
            <v-icon large color="white" v-if="!expand"> mdi-chevron-down </v-icon>
            <v-icon large color="white" v-if="expand"> mdi-chevron-up </v-icon>
          </div>
        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
const props = {
  title: {
    default: "Seccion",
    type: String,
  },
  items: {
    default: {},
    type: Object,
  },
  detailItems: {
    default: [],
    type: Array,
  },
  omitItems: {
    default: [],
    type: Array,
  },
  keys: {
    default: {},
    type: Object,
  },
  section: {
    default: 1,
    type: Number,
  },
  loanId: {
    default: 0,
    type: Number,
  },
  forceExpand: {
    default: false,
    type: Boolean,
  },
  complete: {
    default: false,
    type: Boolean,
  },
};

export default {
  data: () => ({
    expand: false,
  }),
  computed: {
    mainInfo: {
      get() {
        let object = JSON.parse(JSON.stringify(this.items));
        for (let i of this.detailItems) {
          delete object[i];
        }
        for (let i of this.omitItems) {
          delete object[i];
        }
        return object;
      },
    },
    otherInfo: {
      get() {
        let object = {};
        for (let i of this.detailItems) {
          object[i] = this.items[i];
        }
        return object;
      },
    },
  },
  methods: {
    redirect(id, sec) {
      this.$router.push("/solicitud/" + id + "/" + sec);
    },
  },
  props: props,
};
</script>

<style scoped>
.titulo {
  background: #222665;
  color: white;
  padding: 15px;
  display: inline-block;
  border-radius: 0px 0px 20px 20px !important;
  margin-bottom: 1px;
}
.tituloAux {
  background: #222665;
  color: white;
  padding: 15px;
  display: inline-block;
  border-radius: 0px 0px 20px 20px !important;
  margin-bottom: 1px;
}
.semiCircle {
  background: #222665;
  color: white;
  padding: 5px;
  padding-right: 20px;
  padding-left: 20px;
  display: inline-block;
  border-radius: 20px 20px 0px 0px !important;
  cursor: pointer;
}
.paddingList {
  padding: 5px;
}
</style>
