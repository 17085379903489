let exportStructure = {
  Empresas: {
    idempresa: { title: 'ID' },
    nombre: { title: 'Nombre' },
    rfc: { title: 'RFC' },
    telefono: { title: 'Teléfono' },
    email: { title: 'Correo' },
  },
  Zonas: {
    idzona: { title: 'ID' },
    nombre: { title: 'Nombre' },
  },
  Grupos: {
    idgrupo: { title: 'ID' },
    nombre: { title: 'Nombre' },
  },
  'Configuraciones globales': {
    idconfiguracion: { title: 'ID' },
    tipo: { title: 'Tipo' },
    descripcion: { title: 'Descripción' },
  },
  'Autorizar pagos': {
    id: { title: 'ID' },
    solicitud_prestamo: { title: 'ID de solicitud' },
    monto_aprobado: { title: 'Monto aprobado' },
    semana: { title: 'Semana' },
    usuario_creacion_pago: { title: 'Usuario creador de pagos' },
    fecha_pago: { title: 'Fecha de pago' },
    monto_pago: { title: 'Monto pago' },
    estatus_pago: { title: 'Estatus de pago' },
  },
  Convenios: {
    idsolicitud_prestamo: { title: 'ID de solicitud' },
    idcliente: { title: 'ID de cliente' },
    nombre_completo: { title: 'Nombre' },
    monto: { title: 'Monto' },
    semanas: { title: 'No. semanas' },
    semana_inicio: { title: 'Semana de inicio' },
    fecha: { title: 'Fecha creación' },
  },
  'Préstamos activos': {
    idhistorial_solicitud_prestamo: { title: 'ID' },
    tipo: { title: 'Tipo' },
    grupo: { title: 'Grupo' },
    fecha: { title: 'Fecha' },
    cliente: { title: 'Cliente' },
    nombre: { title: 'Nombre' },
    apellido_paterno: { title: 'Apellido paterno' },
    apellido_materno: { title: 'Apellido materno' },
    calle: { title: 'Calle' },
    numero: { title: 'Número' },
    colonia: { title: 'Colonia' },
    telefono: { title: 'Teléfono' },
    monto: { title: 'Monto' },
    clave_elector: { title: 'Clave de elector' },
  },
  Morosos: {
    idmoroso: { title: 'ID' },
    tipo: { title: 'Tipo' },
    grupo: { title: 'Grupo' },
    fecha: { title: 'Fecha' },
    cliente: { title: 'Cliente' },
    nombre: { title: 'Nombre' },
    apellido_paterno: { title: 'Apellido paterno' },
    apellido_materno: { title: 'Apellido materno' },
    calle: { title: 'Calle' },
    numero: { title: 'Número' },
    colonia: { title: 'Colonia' },
    telefono: { title: 'Teléfono' },
    monto: { title: 'Monto' },
    deuda: { title: 'Deuda' },
    fraude: { title: 'Fraude' },
  },
};

export default exportStructure;
