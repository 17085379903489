<template>
  <v-menu max-width="400" v-model="menu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="text-normal" v-bind="attrs" v-on="on">
        {{ userInfo.email }}
        <v-icon v-text="'mdi-account'" dark right />
      </v-btn>
    </template>
    <v-card width="300">
      <v-row class="backgroundImg">
        <v-col cols="12" md="12">
          <div class="text-center">
            <h3 class="text-center" v-text="configure ? 'Configuración' : 'Mi perfil'" />
          </div>
        </v-col>
        <v-col cols="12" class="itemsCenter">
          <v-avatar class="profile" color="grey" size="150" tile>
            <v-img aspect-ratio="1" contain :lazy-src="profile" :src="img ? img : profile" class="img-background" />
          </v-avatar>
        </v-col>
      </v-row>
      <v-row class="text-center" v-if="!configure">
        <v-col cols="12" class="py-0">
          <h5>Nombre</h5>
          <span>
            {{userInfo ? userInfo.detalles_usuarios ? userInfo.detalles_usuarios[0].nombre + ' ' + userInfo.detalles_usuarios[0].apellido_paterno + ' ' + userInfo.detalles_usuarios[0].apellido_materno : 'Desconocido' : 'Desconocido'}}
          </span>
        </v-col>
        <v-col cols="12" class="py-0">
          <h5>Correo</h5>
          <span v-text="userInfo.email" />
        </v-col>
        <v-col cols="12" class="py-0">
          <h5>Roles</h5>
          <Fillter-List :tags="roles" />
        </v-col>
        <v-col cols="12" class="py-0">
          <h5>Telefono</h5>
          <span v-text="userInfo.detalles_usuarios ? userInfo.detalles_usuarios[0].telefono : ''" />
        </v-col>
        <v-col cols="12" class="py-0">
          <h5>Grupos</h5>
          <Fillter-List :tags="userInfo.grupos_encargados" keys="nombre" />
        </v-col>
        <v-col cols="12" class="pt-0">
          <h5>Fecha de Ingreso a la empresa</h5>
          <span v-text="userInfo.detalles_usuarios ? userInfo.detalles_usuarios[0].fecha_ingreso_grupo : 'Desconocido'" />
        </v-col>
       
      </v-row>
      <slot class="text-center" name="configureMenu" v-if="configure" />
      <v-divider></v-divider>
      <v-card-actions>
        <span color="text--white" class="text-caption"> v{{ version }} </span>
        <v-spacer></v-spacer>
        <slot class="text-center" name="exit" />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { BasicBtn, FillterList } from "@/components";

const props = {
  userInfo: {
    default: {},
    type: Object,
  },
  roles: {
    default: [],
    type: Array,
  },
  version: {
    default: "0",
    type: String,
  },
  img: {
    default: "",
    type: String,
  },
  configure: {
    default: false,
    type: Boolean,
  },
};

export default {
  components: {
    BasicBtn,
    FillterList,
  },
  data: () => ({
    menu: false,
    profile: require("@/assets/profile.jpeg"),
  }),

  props: props,
};
</script>

<style scoped>
.text-normal {
  text-transform: none !important;
}
.main-title {
  font-size: 28px;
  color: #222665;
}
.tituloAux {
  background: #222665;
  color: white;
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;

  display: inline-block;
  border-radius: 0px 0px 20px 20px !important;
}
.backgroundImg {
  color: white;
  background: linear-gradient(to bottom, #222665 0%, #222665 70%, white 50%, white 100%) !important;
}
.img-background {
  background-color: white;
}
</style>
