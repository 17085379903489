<template>
  <div class="itemsCenter">
    <v-edit-dialog v-if="tags.length > 0">
     <v-chip class="ma-2" v-for="(tag, i) in tags.slice(0, 2)" :key="i" :close="close" small style="margin: 0"> {{ keys ? tag[keys] : tag }} </v-chip>
      <v-chip v-text="tags.length - 2 + '+'" small v-if="tags.length - 2 > 0" />
      <template v-slot:input>
        <v-chip-group column multiple>
          <v-chip v-text="keys ? tag[keys] : tag" v-for="(tag, n) in tags" :key="n" small />
        </v-chip-group>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
const props = {
  title: {
    default: "",
    type: String,
  },
  errorMsg: {
    default: " Filtros no activos",
    type: String,
  },
  tags: {
    default: [],
    type: Array,
  },
  close: {
    default: false,
    type: Boolean,
  },
  keys: {
    default: null,
    type: String,
  },
};

export default {
  props: props,
};
</script>

<style scoped></style>
