<template>
  <div class="text-center ma-2">
    <v-hover v-slot="{ hover }">
      <v-snackbar class="text-body-1" v-model="showMsg" :color="color" :timeout="stay ? -1 : hover ? -1 : timeout" bottom right>
        <span class="text-body-1">
          {{ msg }}
        </span>
        <br />
        <div v-if="errors">
          <ul v-for="(error, i) in errors" :key="i">
            <li class="text-body-1" v-if="errorSettings.valid ? error[errorSettings.valid] : true">{{ errorSettings.key ? error[errorSettings.key] : error }}</li>
          </ul>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showMsg = false" v-if="!errors"> <v-icon v-text="closeIcon" /> </v-btn>
          <v-btn color="white" text v-bind="attrs" @click="stay = !stay" v-if="errors">{{ stay ? "Cerrar" : "Mantener" }} </v-btn>
        </template>
        <v-progress-linear color="white" :value="progressValue" class="itemsBottom"></v-progress-linear>
      </v-snackbar>
    </v-hover>
  </div>
</template>

<script>
const props = {
  active: {
    default: false,
    type: Boolean,
  },
  color: {
    default: "error",
    type: String,
  },
  closeIcon: {
    default: "mdi-close",
    type: String,
  },
  msg: {
    default: "",
    type: String,
  },
  timeout: {
    default: 2500,
    type: Number,
  },
  errors: {
    default: null,
    type: Array,
  },
  errorSettings: {
    type: Object,
  },
};

export default {
  data: () => ({
    progressValue: 0,
    auxProgress: 0,
    interval: undefined,
    hover1: false,
    stay: false,
  }),
  mounted() {
    this.showMsg = this.active;
  },
  watch: {
    active(state) {
      this.progress(state);
    },
  },

  computed: {
    showMsg: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit("update:active", val);
        this.$emit("update:errors", null);
        this.progressValue = 0;
        return val;
      },
    },
  },
  methods: {
    progress(state) {
      if (state) {
        this.interval = setInterval(() => {
          this.updateProgress();
          if (this.auxProgress > 100) {
            clearInterval(this.interval);
            this.actishowMsgve = false;
          }
        }, this.timeout / 10);
      }
    },
    updateProgress() {
      this.auxProgress += 10;
      this.progressValue = (this.auxProgress * this.timeout) / 2000;
    },
  },
  props: props,
};
</script>

<style scoped>
.itemsBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
