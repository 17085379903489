<template>
  <div>
    <Bread-Crumb v-if="!hideRoute" />
    <v-card class="elevation-5 mt-5 mb-0 mx-5">
      <v-toolbar dense dark :color="color" elevation="2">
        <v-toolbar-title v-text="title" />
        <v-spacer></v-spacer>
        <slot name="header-buttons" />
        <vue-json-to-csv v-if="exportInfo.length > 0" :json-data="exportInfo" :csv-title="$route.name + '_' + dateFormat(new Date())" :labels="exportLabels">
          <v-btn small text>
            Exportar
            <v-icon right>mdi-file-excel-outline</v-icon>
          </v-btn>
        </vue-json-to-csv>
      </v-toolbar>
      <v-card-title>
        <slot class="text-center" name="buttons" />
        <v-spacer></v-spacer>
        <slot class="text-center" name="search" />
      </v-card-title>
      <slot />
      <v-card-text v-if="cardTextFooter">
        <slot class="text-center" name="text" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { BreadCrumb } from "@/components";
import { exportStructure } from "@/utils";
import VueJsonToCsv from "vue-json-to-csv";
import moment from "moment";

const props = {
  title: {
    default: "",
    type: String,
  },
  color: {
    default: "secondary",
    type: String,
  },
  hideRoute: {
    default: false,
    type: Boolean,
  },
  exportInfo: {
    default() { return [] },
    type: Array,
  },
  cardTextFooter: {
    default: false,
    type: Boolean,
  },
};

export default {
  components: {
    BreadCrumb,
    VueJsonToCsv,
  },
  props: props,
  computed: {
    exportLabels() {
      return exportStructure[this.$route.name];
    },
  },
  methods: {
    dateFormat(date) {
      return date ? moment(date).format("YYYY-MM-DD") : "Desconocido";
    },
  },
};
</script>

<style scoped></style>
