let errorsList = {
  details: {
    nombre: { value: "Nombre", required: true },
    apellido_paterno: { value: "Apellido paterno", required: true },
    apellido_materno: { value: "Apellido materno", required: false },
    fecha_nacimiento: { value: "Fecha de nacimiento", required: true },
    estado_civil: { value: "Estado civil", required: true },
    clave_elector: { value: "Clave de elector", required: true },
    telefono_fijo: { value: "Teléfono fijo", required: false },
    telefono_movil: { value: "Teléfono movil", required: true },
    email: { value: "Correo", required: false },
    ocupacion: { value: "Ocupacion", required: true },
    detalle_ocupacion: { value: "Detalle de ocupacion", required: true },
    tipo_casa: { value: "Tipo de vivienda", required: true },
  },
  detailsReference: {
    nombre: { value: "Nombre", required: true },
    apellido_paterno: { value: "Apellido paterno", required: true },
    apellido_materno: { value: "Apellido materno", required: false },
    fecha_nacimiento: { value: "Fecha de nacimiento", required: false },
    estado_civil: { value: "Estado civil", required: true },
    clave_elector: { value: "Clave de elector", required: false },
    telefono_fijo: { value: "Teléfono fijo", required: false },
    telefono_movil: { value: "Teléfono movil", required: true },
    email: { value: "Correo", required: false },
    ocupacion: { value: "Ocupación", required: false },
    detalle_ocupacion: { value: "Detalle de ocupación", required: false },
    tipo_casa: { value: "Tipo de vivienda", required: false },
  },
  family: {
    parentesco: { value: "Parentesco", required: true },
  },
  address: {
    calle: { value: "Calle", required: true },
    num_ext: { value: "Numero de exterior", required: true },
    num_int: { value: "Numero de interior", required: false },
    entre_calles: { value: "Entre calles", required: true },
    referencia: { value: "Referencia", required: true },
    colonia: { value: "Colonia", required: true },
    sector: { value: "Sector", required: false },
    cp: { value: "C.P.", required: true },
    municipio: { value: "Municipio", required: true },
    estado: { value: "Estado", required: false },
    pais: { value: "Pais", required: false },
  },
  employee: {
    nombre: { value: "Nombre", required: true },
    telefono: { value: "Teléfono", required: false },
  },
  income: {
    solicitud: { value: "Solicitud", required: false },
    ingresos_negocios: { value: "Ingresos de negocios", required: true },
    ingresos_empleos: { value: "Ingreso de empleo", required: true },
    total_ingresos_semanal: { value: "Total de ingresos semanales", required: true },
    total_gastos_semanal: { value: "Total de gastos semanales", required: true },
  },
  observation: {
    observaciones: { value: "Observaciones", required: false },
  },
  pledge: {
    nombre: { value: "Articulo", required: true },
    marca: { value: "Marca", required: true },
    modelo: { value: "Modelo", required: false },
    tipo: { value: "Tipo", required: true },
    ano: { value: "Año", required: false },
    color: { value: "Color", required: false },
    numero_serie: { value: "Numero de serie", required: false },
    numero_motor: { value: "Observaciones", required: false },
  },
  amount: {
    tipo_tarjeta: { value: "Tipo de tarjeta", required: true },
    folio_tarjeta: { value: "Folio de tarjeta", required: true },
    monto_aprobado: { value: "Monto autorizado", required: true },
    monto_entregado: { value: "Monto entregado", required: true },
  },
};

export default errorsList;
