<template>
  <v-dialog v-model="estate" persistent max-width="400" style="overflow-x: hidden">
    <v-card>
      <v-card-text>
        <lottie-animation ref="anim" :animationData="require('@/assets/animations/' + animation + '.json')" :key="animation" :loop="true" :autoPlay="true" style="width: 100%; height: 100px" />
        <div class="itemsCenter">
          <h1>{{ animation == 'error'  ? 'Error' : 'Alerta' }}</h1>
        </div>
        <br />
        <v-list subheader two-line>
          <v-list-item v-for="(alert, i) in alerts" :key="i">
            <v-list-item-avatar>
              <v-icon :color="alert.tipo == 2 ? 'error' : ''"> {{ "mdi-numeric-" + (i > 8 ? "9-plus" : i + 1) + "-circle-outline" }} </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <span class="error-font" v-text="alert.mensaje"></span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <slot class="text-center" name="close" />
      </v-card-actions>
      <v-card-actions>
        <slot class="text-center" name="after" v-if="animation != 'error'" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

const props = {
  estate: {
    default: false,
    type: Boolean,
  },
  alerts: {
    default: [],
    type: Array,
  },
};
export default {
  components: {
    LottieAnimation,
  },
  data: () => ({
    animation: "warning",
  }),
  props: props,
  watch: {
    alerts() {
      let auxArray = _.filter(this.alerts, function (o) {
        return o.tipo == 2;
      });
      this.animation = auxArray.length > 0 ? "error" : "warning";
    },
  },
};
</script>

<style scoped>
.error-font {
  font-size: 17px !important;
}
</style>
