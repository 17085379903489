<template>
  <v-breadcrumbs :items="actualPage">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :href="item.path">
        <v-icon color="primary" v-text="item.icon" left />
        <span v-text="item.title" />
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
const props = {};

export default {
  name: "breadcrumb",
  data: () => ({
    items: [
      { icon: "mdi-home", title: "Home", path: "/home" },
      {
        icon: "mdi-cog",
        title: "Configuración",
        role: "*",
        children: [
          { icon: "mdi-account", title: "Usuarios", path: "/usuarios" },
          { icon: "mdi-domain", title: "Empresas", path: "/empresas" },
          { icon: "mdi-map-marker-radius-outline", title: "Zonas", path: "/zonas" },
          { icon: "mdi-account-group", title: "Grupos", path: "/grupos" },
          { icon: "mdi-cash-multiple", title: "Globales", path: "/configuraciones-globales" },
        ],
      },
      {
        icon: "mdi-cash",
        title: "Pagos",
        role: true,
        children: [
          { icon: "mdi-cash-check", title: "Autorizar pagos", path: "/pagos-pendientes" },
        ],
      },
      { icon: "mdi-account-multiple", title: "Clientes", path: "/clientes" },
      { icon: "mdi-file-outline", title: "Mis solicitudes", path: "/mis-solicitudes" },
      { icon: "mdi-file-plus-outline", title: "Nueva solicitud", path: "/solicitud" },
      { icon: "mdi-graph-outline", title: "Reporte de personal", path: "/reporte-personal" },
    ],
  }),
  computed: {
    actualPage() {
      let urls = [];
      for (let route of this.items) {
        if (route.children) {
          for (let route2 of route.children) {
            if (route2.path == this.$route.path) {
              urls.push({ icon: route.icon, title: route.title, path: route.path }, { icon: route2.icon, title: route2.title, path: route2.path });
            }
          }
        } else if (route.path == this.$route.path) {
          urls.push({ icon: route.icon, title: route.title, path: route.path });
        }
      }
      return urls;
    },
  },
  methods: {},
  props: props,
};
</script>

<style scoped></style>
