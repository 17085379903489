<template>
  <div>
    <v-navigation-drawer v-model="openAux" absolute temporary right class="grey lighten-5" width='300'>
      <div class="header-style pa-6">
        <v-row>
          <v-col cols="10">
            <v-toolbar-title class="white--text"> Notificaciones </v-toolbar-title>
          </v-col>
          <v-col cols="2">
            <v-btn fab color="white" bottom x-small dense outlined @click="fillterSection = !fillterSection">
              <v-icon>{{ fillterSection ? "mdi-close" : "mdi-magnify" }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="fillterSection">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Buscar" clearable dense outlined hide-details v-on:input="orderList()" dark></v-text-field>
              </v-col>
              <v-col cols="12">
                <datePicker v-model="begin" label="Fecha inicio" offYear hideDetails offLimit v-on:input="orderList()" dark></datePicker>
              </v-col>
              <v-col cols="12">
                <datePicker v-model="end" label="Fecha fin" offYear hideDetails offLimit v-on:input="orderList()" dark></datePicker>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-tabs v-model="tab" align-with-title background-color="primary" center-active dark>
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab v-for="(item, i) in tabTitles" :key="i">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab" class="grey lighten-5">
        <v-tab-item v-for="(type, t) in types" :key="t">
          <v-list two-line>
            <v-list-item-group active-class="primary--text">
              <template v-for="(item, index) in itemsAux[type]">
                <v-list-item @click="deleteNotification(item)">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.titulo"></v-list-item-title>
                      <v-list-item-subtitle class="text--primary" v-text="item.texto"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="dateFormat(item.fecha)"></v-list-item-action-text>
                      <v-icon> mdi-check </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider v-if="index < items[type].length - 1" :key="index" light></v-divider>
              </template>
            </v-list-item-group>
            <div v-if="items[type] ? items[type].length == 0 : true" class="text-center">
              <lottie-animation ref="anim" :animationData="require('@/assets/animations/notifySleep.json')" :loop="true" :autoPlay="true" style="width: 100%; height: 300px" />
              <h2 class="primary--text">Notificaciones no disponibles</h2>
            </div>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>
  </div>
</template>

<script>
import _ from "lodash";
import LottieAnimation from "lottie-web-vue";
import moment from "moment";
import datePicker from "@/components/form/datePicker.vue";

const props = {
  open: {
    default: false,
    type: Boolean,
  },
  items: {
    default: {},
    type: Object,
  },
};

export default {
  name: "Notification",
  components: {
    LottieAnimation,
    datePicker,
  },
  data: () => ({
    search: "",
    begin: null,
    end: null,
    openAux: false,
    drawer: null,
    host: process.env.VUE_APP_API_SERVER,
    tab: 0,
    fillterSection: false,
    tabTitles: ["NO LEIDOS", "LEIDOS"],
    types:  ["no_leidos", "leidos"],
    itemsAux: {},
  }),
  watch: {
    fillterSection(value) {
      if (value) {
        this.search = "";
        this.begin = null;
        this.end = null;
      }
        this.orderList();
    },
    items() {
     this.itemsAux = JSON.parse(JSON.stringify(this.items));
    },
    open(value) {
      this.openAux = value;
    },
    openAux(value) {
      this.$emit("update:open", value);
    },
  },
  mounted() {
    this.itemsAux = JSON.parse(JSON.stringify(this.items));
  },
  methods: {
    async orderList() {
      let array = this.types[this.tab]
      let str = this.search;
      let end = this.end;
      let begin = this.begin;
      this.itemsAux[array] = _.filter(this.items[array], function (o) {
        return (begin ? new Date(o.fecha) > new Date(begin) : true) && (end ? new Date(o.fecha) < new Date(end) : true) && o.titulo.toLowerCase().indexOf(str.toLowerCase()) >= 0;
      });
    },
    dateFormat(date) {
      if (moment().diff(date, "days") > 1) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return moment(date, "YYYYMMDD H:mm:ss").locale("es").fromNow();
      }
    },
    redirect(data){
      if(data.solicitud_prestamo){
      this.$router.push("/mis-solicitudes");
      }else{
      this.$router.push("/clientes");

      }
    },
    deleteNotification(info) {
      this.loading = true;
      let data = {
        "data": {
            idnotificacion: info.id,

  }

      };
      this.axios
        .delete(this.host + "/api/admin/user/notification", { data })
        .then((response) => {
          this.redirect(info)
        })
    },
  },
  props: props,
};
</script>

<style scoped>
.header-style {
  background-color: #222665;
}
</style>
