<template>
  <v-row>
    <v-col v-for="(item, index) in info" :key="index" :cols="item.col ? item.col : allCols">
      <v-textarea
        v-if="item.input == 'textarea'"
        :prepend-icon="item.icon"
        v-model="value[item.model]"
        :rows="item.rows"
        :label="item.name"
        :rules="validRules(item.rules)"
        :disabled="loading"
        :maxlength="item.max"
      ></v-textarea>
      <v-select v-if="item.input == 'select'" :prepend-icon="item.icon" v-model="value[item.model]" :items="[]" :label="item.name" :rules="validRules(item.rules)" :disabled="loading"></v-select>
      <v-checkbox v-if="item.input == 'checkbox'" :input-value="value[item.model]" :label="item.name" value disabled></v-checkbox>
      <v-text-field
        v-if="item.input == 'text' || !item.input"
        :type="item.type ? item.type : 'text'"
        v-model="value[item.model]"
        :label="item.name"
        @input="(value) => Change(value)"
        :counter="item.counter"
        :autofocus="item.autofocus"
        :prepend-icon="item.icon"
        :loading="loading"
        :disabled="loading || item.disabled"
        :readonly="item.readonly ? item.readonly : readonly"
        :rules="validRules(item.rules)"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
const props = {
  title: {
    default: "",
    type: String,
  },
  readonly: {
    default: false,
    type: Boolean,
  },
  value: {
    type: Object,
  },
  info: {
    type: Array,
  },
  loading: {
    default: false,
    type: Boolean,
  },
  selectItems: {
    type: Array,
  },
  allCols: {
    default: 6,
    type: Number,
  },
};

export default {
  data() {
    return {
      localValue: this.value,
      auxValue: this.value,
      rules: {
        required: (v) => !!v || "El campo es obligatorio",
        number: (v) => {
          if (!!v && v !== "") {
            return /^[0-9]+$/.test(v) || "Número inválido";
          } else {
            return true;
          }
        },
      },
    };
  },

  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
      let value1 = this.localValue;
      this.localValue = value1;
    },
    value(newValue) {
      this.localValue = this.value;
    },
  },
  methods: {
    Change() {
      this.$emit("input", this.value);
    },
    validRules(array) {
      let aux = [];
      if (array) {
        for (let key of array) {
          aux.push(this.rules[key]);
        }
      }
      return aux;
    },
  },
  props: props,
};
</script>

<style scoped></style>
